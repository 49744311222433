import { setMenukey, getMenukey } from '@/utils/auth'
/**
 * @auther Mark
 * @description 获取 Limosa 部分数据, 把resourceKey放进cookie中
 * @param {Array} data permission
 */
export const InitPermissionDate = (data) => {
  let menuKey = []
  let LIMODA = []
  try {
    const Common = data.filter(item => item.type === 'Common')
    Common.forEach(item => {
      const permission = JSON.parse(item.permission)
      const ELSA = permission.filter(itemE => itemE.name === 'ELSA')
      if (ELSA.length) LIMODA = ELSA[0].subMenu.filter(itemL => itemL.resourceKey === 'app_limosaWebsite')
      if (LIMODA.length) {
        let itemArr = getDisplayMenuKey(LIMODA[0])
        itemArr.forEach(itemA => {
          !menuKey.includes(itemA) && menuKey.push(itemA)
        })
      }
    })
  } catch (e) {}
  setMenukey(menuKey)
}

/**
 * @auther Mark
 * @description 遍历数组获取需要显示的 resourceKey
 * @param {Arrau} menus
 * @return {Array} displayMenuKey
 */
const getDisplayMenuKey = (menus) => {
  let displayMenuKey = []
  menus.subMenu.forEach(item => {
    if (item.configs.enabled) {
      displayMenuKey.push(item.resourceKey)
      if (item.subMenu.length) {
        displayMenuKey.push(...getDisplayMenuKey(item))
      }
    }
  })
  return displayMenuKey
}
/**
 * @auther Mark
 * @description 设置路由meta信息的available的true和false
 * @param {Object} routesChild 的信息
 */
export const setMenukeyByRole = (routesChild) => {
  const Menukey = getMenukey() || []
  routesChild.forEach(item => {
    if (Menukey.includes(item.meta.resourceKey)) {
      item.meta.available = true
      if (item?.children?.length) {
        setMenukeyByRole(item.children)
      }
    }
  })
}
/**
 * @auther Mark
 * @description 把所有的available改为false
 * @param {Object} routesChild 的信息
 */
export const reSetMenukeyByRole = (routesChild) => {
  routesChild.forEach(item => {
    item.meta.available = false
    if (item?.children?.length) {
      reSetMenukeyByRole(item.children)
    }
  })
}
