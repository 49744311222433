<template>
  <section class="login_bg">
    <div class="login_bg2"></div>
    <div class="login_bg3">
      <img class="svg-icon" src="../../assets/login/login.png" alt="">
    </div>
    <div class="loginPane">
      <div class="login-wrap">
        <img src="../../assets/login/logo2.png" class="loginImg" />
        <div class="panel_bg"></div>
        <div class="loginInputBox">
          <el-form
              ref="ruleForm"
              :model="ruleForm"
              status-icon
              :rules="rules"
              label-width="0px"
              class="demo-ruleForm"
            >
              <el-form-item prop="username" >
                <el-input
                  v-model="ruleForm.username"
                  autocomplete="off"
                  placeholder="用户名"
                  prefix-icon="el-icon-user"
                  clearable
                />
              </el-form-item>
              <el-form-item
                prop="password"
                style="margin-bottom:0"
              >
                <el-input
                  v-model="ruleForm.password"
                  type="password"
                  autocomplete="off"
                  placeholder="用户密码"
                  prefix-icon="el-icon-unlock"
                  clearable
                  show-password
                  @keyup.native.enter="login('ruleForm')"
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="mt-20 loginBtn"
                  @click="login('ruleForm')"
                >
                  Login
                </el-button>
              </el-form-item>
            </el-form>
        </div>
      </div>
    </div>
    <Loading></Loading>
  </section>
</template>

<script>
import Loading from '../../components/loading.vue'
import { InitPermissionDate, setMenukeyByRole, reSetMenukeyByRole } from '@/utils/route'
import * as bookingApi from '../../api/booking/booking-api'
import { setAwbGroup, setAwbType, setBookingType, setBusinessService, setCargoStatus, setCargoType, setDimensionUnit, setDocName, setExportImportFlag, setFrtPayType, setIncoterm, setIsECom, setIsReceived, setOtherPayType, setPackageUnit, setPickupDeliveryMethod, setSentMethod, setServiceLevel, setServiceScope, setServiceType, setShowNotifyPartyOnAWB, setBookingStatus, setTransferFlag, setValueAddedServiceCode, setVolumeUnit, setWeightUnit } from '@/utils/booking/bookingOptions'
export default {
  name: 'Login',
  components: {
    Loading
  },
  data () {
    var validateUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('The User ID cannot be empty'))
      } else {
        return callback()
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('Please input a password'))
      } else {
        return callback()
      }
    }
    return {
      isLoading: false,
      checked: false,
      ruleForm: {
        username: '',
        password: ''
      },
      rules: {
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        username: [
          { validator: validateUserName, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    login (formName) {
      this.isLoading = true
      const homeChildren = this.$router.options.routes.filter(item => item.name === 'Home')
      // step 1 重置 available = false
      homeChildren.length && reSetMenukeyByRole(homeChildren[0].children)
      this.$refs[formName].validate(valid => {
        if (valid) {
          Object.keys(this.ruleForm).forEach(key => {
            this.ruleForm[key] = this.ruleForm[key].trim()
          })
          this.$store.dispatch('login', this.ruleForm).then(res => {
            this.$router.push({
              name: 'Home'
            })
            // step 2 初始化 permission 信息
            InitPermissionDate(res)
            // InitPermissionDate(res)
            // step 3 设置需要显示的菜单 available = true
            homeChildren.length && setMenukeyByRole(homeChildren[0].children)
            // step 4 重新渲染菜单
            this.$store.commit('changRefreshMenu')
            // step 5 如果存在role: "LIMOSA_BOOKING"，查询全部optionListData
            // let result = res.some((item) => item.role === 'LIMOSA_BOOKING')
            // eslint-disable-next-line no-unused-expressions
            // result ? this.queryBookingOptionList() : ''
          }).catch(err => {
            console.log(err)
            this.$message.error(err)
            // this.$message({
            //   dangerouslyUseHTMLString: true,
            //   message: `Failed. Unexpected service error happens.
            //         Please re-upload files to try again.
            //         Or contact supporter for help: limosa_sup@icil.net`,
            //   type: 'error',
            //   showClose: true,
            //   duration: 5000,
            //   offset: 72
            // })
          })
        } else {
          return false
        }
      })
    },
    queryBookingOptionList () { // 查询Booking optionListData
      bookingApi.queryOptionsData().then((res) => {
        if (res.data.code === 200) {
          setAwbGroup(res.data.data.EXHKGBookingDependency.optionListData.awbGroup)
          setAwbType(res.data.data.EXHKGBookingDependency.optionListData.awbType)
          setBookingType(res.data.data.EXHKGBookingDependency.optionListData.bookingType)
          setBusinessService(res.data.data.EXHKGBookingDependency.optionListData.businessService)
          setCargoStatus(res.data.data.EXHKGBookingDependency.optionListData.cargoStatus)
          setCargoType(res.data.data.EXHKGBookingDependency.optionListData.cargoType)
          setDimensionUnit(res.data.data.EXHKGBookingDependency.optionListData.dimensionUnit)
          setDocName(res.data.data.EXHKGBookingDependency.optionListData.docName)
          setExportImportFlag(res.data.data.EXHKGBookingDependency.optionListData.exportImportFlag)
          setFrtPayType(res.data.data.EXHKGBookingDependency.optionListData.frtPayType)
          setIncoterm(res.data.data.EXHKGBookingDependency.optionListData.incoterm)
          setIsECom(res.data.data.EXHKGBookingDependency.optionListData.isECom)
          setIsReceived(res.data.data.EXHKGBookingDependency.optionListData.isReceived)
          setOtherPayType(res.data.data.EXHKGBookingDependency.optionListData.otherPayType)
          setPackageUnit(res.data.data.EXHKGBookingDependency.optionListData.packageUnit)
          setPickupDeliveryMethod(res.data.data.EXHKGBookingDependency.optionListData.pickupDeliveryMethod)
          setSentMethod(res.data.data.EXHKGBookingDependency.optionListData.sentMethod)
          setServiceLevel(res.data.data.EXHKGBookingDependency.optionListData.serviceLevel)
          setServiceScope(res.data.data.EXHKGBookingDependency.optionListData.serviceScope)
          setServiceType(res.data.data.EXHKGBookingDependency.optionListData.serviceType)
          setShowNotifyPartyOnAWB(res.data.data.EXHKGBookingDependency.optionListData.showNotifyPartyOnAWB)
          setBookingStatus(res.data.data.EXHKGBookingDependency.optionListData.status)
          setTransferFlag(res.data.data.EXHKGBookingDependency.optionListData.transferFlag)
          setValueAddedServiceCode(res.data.data.EXHKGBookingDependency.optionListData.valueAddedServiceCode)
          setVolumeUnit(res.data.data.EXHKGBookingDependency.optionListData.volumeUnit)
          setWeightUnit(res.data.data.EXHKGBookingDependency.optionListData.weightUnit)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login_bg{
  width: 100%;
  height: 934px;
  background-color: #fff;
  position: relative;
  .login_bg3 {
    position: absolute;
    top: 60px;
    left: 50%;
    margin-left: -90px;
    z-index: 5;
    .svg-icon {
      width: 180px;
      fill: currentColor;
      overflow: hidden;
    }
  }
  .login_bg2 {
    width: 100%;
    height: 400px;
    background-color: #4a8cef;
    position: absolute;
    top: 50%;
    margin-top: -200px;
  }
  .loginPane{
    width: 550px;
    height: 400px;
    background-color: #fff;
    border-radius: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: ease all 0.5s;
    box-shadow: 0 5px 10px #4a8cef59;
    .login-wrap{
      position: relative;
      .panel_bg{
        position: absolute;
        left: 0;
        bottom: -85px;
        width: 100%;
        height: 148px;
        background-color: #fff;
      }
    }
    .loginImg{
      display: block;
      width: 180px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 60px;
      margin-bottom: 12px;
    }
    .loginInputBox{
      width: 70%;
      margin: 0 auto;
      .mt-20 {
        margin-bottom: 20px;
      }
      .loginBtn {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
::v-deep .el-form-item__error{
  color:azure;
}
</style>
