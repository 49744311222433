<template>
  <div class="container">
    <div class="nav-img">
      <img src="@/assets/login/UFL_logo_200x40px.png" :style="(this.$store.state.isCollapse === false?'width:200px;':'width:0px;')+'height: 40px;transition: width 0.5s ease 0s;margin-left:12px;'">
    </div>
    <el-menu
      :key="$store.getters.refreshMenu"
      :default-active="actived"
      class="el-menu-vertical-demo"
      @select="menuSelect"
      :collapse="this.$store.state.isCollapse"
      collapse-transition
      :width="this.$store.state.isCollapse === false ? '256px' : '64px'"
      router>
      <!-- 主要遍历一级菜单 -->
      <template v-for="(item, index) in routerMenu">
        <component
          v-if="item.meta.available"
          :is="item.meta.showChildren ? 'subMenu' : 'menuItem'"
          :routerMenu="item.children"
          :routeItem="item"
          :actived="actived"
          :findElem="findElem"
          :key="index">
        </component>
      </template>
    </el-menu>
  </div>
</template>

<script>
import menuItem from './menu/menu-item.vue'
import subMenu from './menu/sub-menu.vue'
export default {
  name: 'Aside',
  components: {
    menuItem,
    subMenu
  },
  data () {
    return {
      actived: this.$route.path, // 高亮
      routerMenu: []// 菜单列表
    }
  },
  created () {
    this.getrouterMenu()
  },
  methods: {
    getrouterMenu () {
      this.routerMenu = this.$router.options.routes[2].children
    },
    menuSelect (index) { // 菜单激活回调
      this.actived = index
    },
    findElem (array, val) {
      val = this.$route.path
      // const arrPath = val.split('/')
      // if (arrPath.indexOf(array.slice(1)) !== -1) {
      //   return 1
      // }
      if (val.indexOf(array) !== -1) {
        return 1
      }
      return -1
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        this.actived = val.path
        this.getrouterMenu()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  height: 100vh;
  position: fixed;
  z-index: 5;
  box-shadow: 2px 0px 8px 0px #00000014;
  transition: width 2s;
  background-color: $bg-color;
  .nav-img {
    width: calc( 100% - 12px);
    height: 48px;
    margin: 20px 0 8px 0;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
  }
  .el-menu {
    border-right: none;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: $bg-color;
    text-align: left;
    .nav-actived {
      color: #1890ff !important;
    }
    .el-submenu{
      .el-menu-item {
        height: 40px;
        line-height: 22px;
        padding: 9px 93px 9px 53px !important;
        min-width: 115px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        .is-active {
          // color: #303133;
          border-right: 3px solid #e5e5e5;
          transition:none;
        }
      }
    }
    .el-submenu__title {
      height: 40px !important;
      line-height: 40px !important;
      margin-bottom: 8px;
    }
    .el-menu-item {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 256px;
  }
}
::v-deep {
  .is-active {
    color: #303133 !important;
  }
}
</style>
