/*
 * @Author: Dana Zhu
 * @Date: 2022-10-21 16:48:16
 * @Last Modified by: Dana Zhu
 * @Last Modified time: 2022-12-08 17:36:49
 */
import Vue from 'vue'
import Vuex from 'vuex'
import {
  adjugeReceivingDocument,
  addActionOfBookingList
} from '../../../utils/booking/tools'
import { queryBookingList } from '../../../api/booking/booking-api'
import {
  setQueryValue,
  getQueryValue,
  removeQueryValuekey,
  getBookingList,
  setBookingList
} from '@/utils/booking/common'

Vue.use(Vuex)

const defaultQueryCondistion = {
  andConditions: [
    {
      field: 'status',
      operator: '=',
      value: 'W'
    }
  ],
  pageSize: 20,
  currentPage: 1
}
const defaultQueryParams = {
  bookingNo: '',
  awbNo: '',
  awbGroup: '',
  mawbNo: '',
  bookingType: '',
  bookingDateLoc: '',
  bookedBy: '',
  status: 'W',
  transferFlag: '',
  cargoStatus: '',
  origin: '',
  dest: '',
  customerCode: '',
  customerName: '',
  shipper: '',
  shipperName: '',
  cons: '',
  consName: '',
  pickupDeliveryMethod: '',
  estPickupDelDateLoc: '',
  estArrivalLeftWarehouseDateLoc: '',
  actPickupDelDateLoc: '',
  Carrier1st: '',
  FltNum1st: '',
  FltDateLoc1st: ''
}
const state = {
  defaultQueryParams: defaultQueryParams,
  queryConditions: getQueryValue() ? getQueryValue().queryConditions : defaultQueryCondistion,
  queryConditionParams: getQueryValue() ? getQueryValue().queryConditionParams : null,
  tableTotal: getQueryValue() ? getQueryValue().total : null,
  bookingList: getBookingList() ? getBookingList() : null
}

const getters = {
  getQueryConditions: (state) => state.queryConditions,
  getQueryConditionParams: (state) => state.queryConditionParams,
  getTableTotal: (state) => state.tableTotal,
  getBookingListCache: (state) => state.bookingList
}

const mutations = {
  setQueryConditions: (state, value) => {
    // console.log(value)
    state.queryConditions = value.queryConditions
    state.queryConditionParams = value.queryConditionParams || state.queryConditionParams
    state.tableTotal = value.total
    // state.bookingList = value
    setQueryValue(value)
  },
  resetQueryConditions: (state, data) => {
    state.queryConditions = defaultQueryCondistion
    state.queryConditionParams = {
      bookingNo: '',
      awbNo: '',
      awbGroup: '',
      mawbNo: '',
      bookingType: '',
      bookingDateLoc: '',
      bookedBy: '',
      status: 'W',
      transferFlag: '',
      cargoStatus: '',
      origin: '',
      dest: '',
      customerCode: '',
      customerName: '',
      shipper: '',
      shipperName: '',
      cons: '',
      consName: '',
      pickupDeliveryMethod: '',
      estPickupDelDateLoc: '',
      estArrivalLeftWarehouseDateLoc: '',
      actPickupDelDateLoc: '',
      Carrier1st: '',
      FltNum1st: '',
      FltDateLoc1st: ''
    }
    state.tableTotal = 0
    removeQueryValuekey()
  },
  setAllBookingList: (state, data) => {
    state.bookingList = data
    setBookingList(data)
  },
  updateBookingList: (state, updateData) => {
    updateData.forEach((element) => {
      element.disabledTooltip = true
      element.customerCodeAndName = (element.customerInfo.customerCode && element.customerInfo.customerName) ? `[${element.customerInfo.customerCode}] ${element.customerInfo.customerName}` : '-'
      element.shipperCodeAndName = (element.shipperInfo.shipper && element.shipperInfo.shipperName) ? `[${element.shipperInfo.shipper}] ${element.shipperInfo.shipperName}` : '-'
      element.consigneeCodeAndName = (element.consigneeInfo.cons && element.consigneeInfo.consName) ? `[${element.consigneeInfo.cons}] ${element.consigneeInfo.consName}` : '-'
      element.estPickupDateTime = element.pickupAndDelivery[0].estPickupDelDateLoc ? element.pickupAndDelivery[0].estPickupDelDateLoc : ''
      element.estArrivalDateTime = element.pickupAndDelivery[0].estArrivalLeftWarehouseDateLoc
      element.actPickupDateTime = element.pickupAndDelivery[0].actPickupDelDateLoc
      element.actArrivalDateTime = element.pickupAndDelivery[0].actArrivalLeftWarehouseDateLoc
      element.receivingDocument = element.exportDocs ? adjugeReceivingDocument(element.exportDocs) : ''
      element.carrierAndFlightNo = element.flightInfo.Carrier1st ? `${element.flightInfo.Carrier1st}/${element.flightInfo.FltNum1st}` : element.flightInfo.FltNum1st ? element.flightInfo.FltNum1st : '-'
      element.flightDateTime = element.flightInfo.FltDateLoc1st
      element.actionFrameList = addActionOfBookingList(element.status, element.transferFlag) // 根据bookingStatus添加actionFrameList
      state.bookingList = state.bookingList.map((el) => { // updateBooking
        return element.bookingNo === el.bookingNo ? element : el
      })
      let result = state.bookingList.some((ele) => { // AddBooking
        if (element.bookingNo === ele.bookingNo) return true
      })
      if (!result) {
        if (state.bookingList.length >= 10) {
          state.bookingList.unshift(element)
          state.bookingList.splice(-1, 1)
        } else {
          state.bookingList.unshift(element)
        }
      }
    })
    setBookingList(state.bookingList)
  }
}

const actions = {
  queryBookingList (context) {
    return new Promise((resolve, reject) => {
      queryBookingList(context.state.queryConditions)
        .then((res) => {
          if (res.data.code === 200) {
            res.data.data.datas && res.data.data.datas.forEach((item) => {
              item.disabledTooltip = true
              item.customerCodeAndName = (item.customerInfo.customerCode && item.customerInfo.customerName) ? `[${item.customerInfo.customerCode}] ${item.customerInfo.customerName}` : '-'
              item.shipperCodeAndName = (item.shipperInfo.shipper && item.shipperInfo.shipperName) ? `[${item.shipperInfo.shipper}] ${item.shipperInfo.shipperName}` : '-'
              item.consigneeCodeAndName = (item.consigneeInfo.cons && item.consigneeInfo.consName) ? `[${item.consigneeInfo.cons}] ${item.consigneeInfo.consName}` : '-'
              item.estPickupDateTime = item.pickupAndDelivery[0].estPickupDelDateLoc ? item.pickupAndDelivery[0].estPickupDelDateLoc : ''
              item.estArrivalDateTime = item.pickupAndDelivery[0].estArrivalLeftWarehouseDateLoc
              item.actPickupDateTime = item.pickupAndDelivery[0].actPickupDelDateLoc
              item.actArrivalDateTime = item.pickupAndDelivery[0].actArrivalLeftWarehouseDateLoc
              item.receivingDocument = item.exportDocs ? adjugeReceivingDocument(item.exportDocs) : ''
              item.carrierAndFlightNo = item.flightInfo.Carrier1st ? `${item.flightInfo.Carrier1st}/${item.flightInfo.FltNum1st}` : item.flightInfo.FltNum1st ? item.flightInfo.FltNum1st : '-'
              item.flightDateTime = item.flightInfo.FltDateLoc1st
              item.actionFrameList = addActionOfBookingList(item.status, item.transferFlag) // 根据bookingStatus添加actionFrameList
            })
            context.commit('setAllBookingList', res.data.data.datas)
          } else {
            reject(res.data.message)
          }
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
