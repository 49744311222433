const moment = require('moment-timezone')
moment.tz.setDefault('Asia/Shanghai')

const dateFormator = {
  getYMDHms (timestamp) {
    const retimeStamp = moment(timestamp).format('YYYYMMDDHHmmss')
    return retimeStamp
  },
  get3monthsDay () {
    var date1 = new Date()
    date1.setMonth(date1.getMonth() - 3)
    var year1 = date1.getFullYear()
    var month1 = date1.getMonth() + 1
    month1 = (month1 < 10 ? '0' + month1 : month1)
    return year1.toString() + '-' + month1.toString() + '-' + date1.getDate().toString()
  },
  getMonthsDayNow () {
    var date1 = new Date()
    var month1 = date1.getMonth() + 1
    return date1.getFullYear().toString() + '-' + month1.toString() + '-' + date1.getDate().toString()
  }
}

export default dateFormator
