<template>
    <el-menu-item
      v-if="routeItem.meta.available"
      :index="routeItem.path.toString()"
    >
      <svg-icon
        v-if="showIcon"
        :icon-class="findElem(routeItem.path, actived) != -1 ? routeItem.meta.activeIcon : routeItem.meta.defaultIcon"
        style="vertical-align: -6px; margin-right: 8px"/>
      <span
        slot="title"
        class="menu-title"
        :class="findElem(routeItem.path, actived) != -1 ? 'nav-actived' : ''"
        >{{ routeItem.meta.title }}</span
      >
    </el-menu-item>
</template>
<script>
export default {
  name: 'menuItem',
  props: {
    routeItem: {
      type: Object
    },
    actived: {
      type: String,
      default: ''
    },
    findElem: {
      type: Function
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-title {
  font-size: 16px;
}
.el-menu-item {
  // .is-active {
  //   color: #303133;
  // }
  background-color: $bg-color;
}
.nav-actived {
  color: #1890ff !important;
}
</style>
