import request from '../../utils/request'
import { getToken, getName } from '../../utils/auth'

export function loginServer (obj) {
  return request({
    method: 'post',
    url: '/limosa/login',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: obj,
    loading: true
  })
}

export function logoutServer () {
  let offest = '' + new Date()
  let gmt = offest.indexOf('GMT')
  let timezone = offest.substring(gmt + 0, gmt + 8)
  return request({
    method: 'post',
    url: '/limosa/loginOut',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + getToken(),
      timezone: timezone,
      username: getName()
    },
    loading: true
  })
}
