export const EDI = [
  {
    path: '/edi',
    name: 'edi',
    component: () => import('../../views/edi/index.vue'),
    meta: {
      title: 'EDI',
      activeMenu: '/edi',
      activeIcon: 'edi-focus',
      defaultIcon: 'edi',
      showChildren: true, // 是否有子菜单
      resourceKey: 'menu_edi',
      available: false
    },
    children: [
      {
        path: '/edi/invices',
        name: 'Invoices',
        redirect: '/edi/invices/portal',
        component: () => import('../../views/edi/invoice/index.vue'),
        meta: {
          title: 'Invoice',
          activeMenu: '/edi',
          showChildren: true, // 是否有子菜单
          resourceKey: 'menu_invoice',
          available: false
        },
        children: [
          {
            path: '/edi/invices/portal',
            name: 'Portal',
            meta: {
              title: 'Portal',
              showMenu: true,
              defaultIcon: 'filter',
              resourceKey: 'menu_portal',
              available: false
            },
            component: () => import('../../views/edi/invoice/portal/portal-list.vue')
          }
        ]
      }
    ]
  }
]
