/* * @Author: MiniWei  * @Date: 2022-10-27 12:22:51  * @Last Modified by:   Dana Zhu  * @Last Modified time: 2022-10-27 12:22:51  */
const moment = require('moment-timezone')
moment.tz.setDefault('Europe/London')

const dateFormator = {
  transTimeFormat (timestamp) {
    var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
    var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return Y + M + D + h + m + s
  },
  GMTTime (timestamp) {
    var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var utcDate = date.toUTCString()
    const retimeStamp = moment(utcDate).format('YYYY/MM/DD HH:mm')
    return retimeStamp
  },
  GMTDateTime (timestamp) {
    var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var utcDate = date.toUTCString()
    const retimeStamp = moment(utcDate).format('DD/MM/YYYY HH:mm:ss')
    return retimeStamp
  },
  GMTTimeTimestamp (timestamp) {
    const retimeStamp = moment.tz(timestamp, 'Europe/London').valueOf()
    return retimeStamp
  },
  getYMD (timestamp) {
    const retimeStamp = moment(timestamp).format('YYYY-MM-DD')
    return retimeStamp
  },
  getDMY (timestamp) {
    const retimeStamp = moment(timestamp).format('DD-MMM-YY')
    return retimeStamp
  },
  getDM (timestamp) {
    const retimeStamp = moment(timestamp).format('DD-MMM')
    return retimeStamp
  }
}

export default dateFormator
