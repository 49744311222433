// 导入axios
import axios from 'axios'
import { getToken, getName, getOfficeCode } from '@/utils/auth'
import { Message, MessageBox } from 'element-ui'
import store from '@/store'
import config from '../utils/config'
// import router from '../router/index'
// 1. 创建新的axios实例，
const service = axios.create({
  // baseURL: process.env.VUE_APP_URL,
  baseURL: config.BASE_URL,
  timeout: 60000 * 3000
})

// 在请求接口前创建loading参数
// const options = {
//   text: 'Loading...',
//   background: 'rgba(255,255,255,0.6)',
//   lock: true,
//   target: 'body'
// }
// let loadingInstance

let reqNum = 0
const startLoading = () => {
  if (reqNum === 0) {
    // loading开始
    store.commit('common/SET_LOADING', true)
  }
  reqNum++
}
const endLoading = () => {
  if (reqNum <= 0) return
  reqNum--
  if (reqNum === 0) {
    // loading结束
    store.commit('common/SET_LOADING', false)
  }
}

// // 2.请求拦截器
service.interceptors.request.use(config => {
  config.loading && startLoading()
  const token = getToken()
  let offest = '' + new Date()
  let gmt = offest.indexOf('GMT')
  let timezone = offest.substring(gmt + 0, gmt + 8)
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
    config.headers.timezone = timezone
  }
  const username = getName()
  if (username) {
    config.headers.username = username
  }
  config.headers.application = 'limosa'

  // 添加office在header中
  const office = getOfficeCode()
  if (office) {
    config.headers.office = office
  }

  // 发起请求前先判断之前的loading是否存在
  // if (loadingInstance) {
  //   loadingInstance.close()
  // }
  // 开启loading
  // loadingInstance = Loading.service(options)
  return config
}, error => {
  Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
  // 接收到响应数据并成功后的一些共有的处理，关闭loading等
  // 关闭loading组件效果
  const { data } = response
  const whiteList = ['/service/invoice/portal', '/service/regenerate/invoice', 'service/invoice/advancedQuery']
  if (data.CODE === 4000 && !whiteList.includes(response.config.url)) {
    Message.error('系统异常')
  }
  endLoading()
  // loadingInstance.close()
  return response
}, error => {
  // 接收到异常响应的处理开始
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = 'Request error.'
        break
      case 401:
        break
      case 403:
        error.message = 'Access denied.'
        break
      case 404:
        error.message = 'Request error, the resource was not found.'
        // window.location.href = '/NotFound'
        break
      case 405:
        error.message = 'Request method not allowed.'
        break
      case 408:
        error.message = 'Request timeout.'
        break
      case 500:
        error.message = 'Server side error.'
        break
      case 501:
        error.message = 'Network not implemented.'
        break
      case 502:
        error.message = 'Network error.'
        break
      case 503:
        error.message = 'Service Unavailable.'
        break
      case 504:
        error.message = 'Network Timeout.'
        break
      case 505:
        error.message = 'The request is not supported by the HTTP version.'
        break
      default:
        error.message = `Connection error ${error.response.status}.`
    }
    if (error.response.status === 401) {
      MessageBox.confirm(
        'Login time out, please click OK go to Login page',
        '', {
          confirmButtonText: 'OK',
          showCancelButton: false,
          showClose: false,
          closeOnClickModal: false,
          type: 'warning'
        }
      ).then(() => {
        // location.reload()// 为了重新实例化 vue-router 对象，避免 bug
        store.dispatch('logout').then(() => {
          location.reload()// 为了重新实例化 vue-router 对象，避免 bug
        })
      })
    }
  } else {
    // 超时处理
    // if (JSON.stringify(error).includes('timeout')) {
    //   Message.error('服务器响应超时，请刷新当前页')
    // }
    error.message = 'Failed to connect to the server.'
    Message.error(error.message)
  }
  // 关闭loading组件效果
  endLoading()
  if (error.response && error.response.status !== 401) {
    Message.error(error.message)
  }
  // 处理结束
  // 如果不需要错误处理，以上的处理过程都可省略
  return Promise.resolve(error.response)
})

// 4.导入文件
export default service
