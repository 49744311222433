const state = () => ({
  isLoading: false
})

const mutations = {
  SET_LOADING (state, data) {
    state.isLoading = data
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
