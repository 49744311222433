<template>
  <div class="header-display">
    <div class="header-left" >
      <div class="header-icon-box" @click="stretchAside()">
        <svg-icon v-if="this.$store.state.isCollapse === true" class="header-icon" icon-class="MenuUnfold" />
        <svg-icon v-if="this.$store.state.isCollapse === false" class="header-icon" icon-class="MenuFold" />
      </div>
      <div class="header-department">Office: {{ this.officeCode }}</div>
      <!-- <div class="header-department">
        <el-select clearable v-model="value" placeholder="Select" @change="filterOfficecode">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.officeCode"
            :value="item.value">
          </el-option>
        </el-select>
      </div> -->
    </div>
    <div class="header-right">
      <span class="header-eyes header-eyes-icon" @click="switchRobot()"><svg-icon icon-class="robot_up"/></span>
      <span class="header-eyes header-eyes-icon" @click="switchMode()"><svg-icon :icon-class="eyes"/></span>
      <svg-icon icon-class="notification" class="header-right-icon"/>
      <el-popover placement="bottom-end" width="200" trigger="click">
        <div class="action-print" @click="logout()">Logout</div>
        <div slot="reference" class="header-right">
          <span class="header-right-avatar">
            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
          </span>
          <span class="header-admin">{{ this.userName }}</span>
        </div>
      </el-popover>
      <Chatbot v-show="showChatbot" :showChatbot="showChatbot" @hide-Chatbot="hideChatbot"></Chatbot>
    </div>
  </div>
</template>

<script>
import { getName, getOfficeCode } from '@/utils/auth'
import Chatbot from '../chatbot/chatbot-page.vue'
// import * as bookingApi from '../../api/booking/booking-api'
// import { adjugeReceivingDocument, addActionOfBookingList } from '../../utils/booking/tools'
export default {
  data () {
    return {
      themeMode: 'Eye protection mode',
      eyes: 'eyes',
      userName: getName(),
      officeCode: getOfficeCode(),
      showChatbot: false
      // options: [{
      //   value: '选项1',
      //   officeCode: 'EXHKG'
      // }, {
      //   value: '选项2',
      //   officeCode: 'ACRJN'
      // }],
      // value: '',
      // displayDataList: this.$store.getters['booking/getBookingListCache'] || [],
      // currentPage: this.$store.getters['booking/getQueryConditions'] ? this.$store.getters['booking/getQueryConditions'].currentPage : 1, // 当前页数
      // pageSize: this.$store.getters['booking/getQueryConditions'] ? this.$store.getters['booking/getQueryConditions'].pageSize : 10, // 当前页条数
      // total: this.$store.getters['booking/getTableTotal'] || 0 // table list总数据条数
    }
  },
  components: {
    Chatbot
  },
  mounted () {
  },
  methods: {
    // 通过点击Officecode下拉列表某项做出改变
    // async filterOfficecode (code) {
    //   console.log(code)
    //   this.currentPage = 1
    //   if (code) {
    //     await this.saveQueryConditions()
    //     this.queryBookingList(this.$store.getters['booking/getQueryConditions'], true)
    //   }
    // },
    // async queryBookingList (queryValue, isConditionQuery, office) { // Query all booking list
    //   let regex = {
    //     andConditions: [{
    //       field: 'code',
    //       operator: '=',
    //       value: '选项1'
    //     }],
    //     pageSize: this.pageSize,
    //     currentPage: this.currentPage
    //   }
    //   let params = this.$store.getters['booking/getQueryConditions'] || regex
    //   // let bookingStatus = null
    //   await bookingApi.queryBookingList(params, office).then((res) => {
    //     switch (res.data.code) {
    //       case 200:
    //         if (this.queryDrawer) { // 关闭query窗口
    //           this.queryDrawer = false
    //         }
    //         // 分页数据
    //         this.total = res.data.data.totalNum
    //         // 显示的Booking 数据
    //         this.displayDataList = res.data.data.datas
    //         this.displayDataList && this.displayDataList.forEach((item) => {
    //           item.disabledTooltip = true
    //           item.customerCodeAndName = (item.customerInfo.customerCode && item.customerInfo.customerName) ? `[${item.customerInfo.customerCode}] ${item.customerInfo.customerName}` : '-'
    //           item.shipperCodeAndName = (item.shipperInfo.shipper && item.shipperInfo.shipperName) ? `[${item.shipperInfo.shipper}] ${item.shipperInfo.shipperName}` : '-'
    //           item.consigneeCodeAndName = (item.consigneeInfo.cons && item.consigneeInfo.consName) ? `[${item.consigneeInfo.cons}] ${item.consigneeInfo.consName}` : '-'
    //           item.estPickupDateTime = item.pickupAndDelivery[0].estPickupDelDateLoc ? item.pickupAndDelivery[0].estPickupDelDateLoc : ''
    //           item.estArrivalDateTime = item.pickupAndDelivery[0].estArrivalLeftWarehouseDateLoc
    //           item.actPickupDateTime = item.pickupAndDelivery[0].actPickupDelDateLoc
    //           item.actArrivalDateTime = item.pickupAndDelivery[0].actArrivalLeftWarehouseDateLoc
    //           item.receivingDocument = item.exportDocs ? adjugeReceivingDocument(item.exportDocs) : ''
    //           item.carrierAndFlightNo = item.flightInfo.Carrier1st ? `${item.flightInfo.Carrier1st}/${item.flightInfo.FltNum1st}` : item.flightInfo.FltNum1st ? item.flightInfo.FltNum1st : '-'
    //           item.flightDateTime = item.flightInfo.FltDateLoc1st
    //         })
    //         addActionOfBookingList() // 根据bookingStatus添加actionFrameList
    //         if (this.displayDataList.length === 0) {
    //           // 公共白板样式
    //           this.boardObj = {
    //             type: 'noData',
    //             textList: ['No booking items.'],
    //             button: []
    //           }
    //         } else if (this.displayDataList.length === 1 && isConditionQuery) { // 如果查询结果只有一条，则直接进入详情页
    //           this.$router.push({
    //             name: 'view-booking-detail',
    //             query: { bookingNo: this.displayDataList[0].bookingNo }
    //           })
    //         } else {
    //           this.boardObj = null
    //         }
    //         break
    //       case 1004:
    //         this.displayDataList = []
    //         this.boardObj = { // Systerm error
    //           type: 'noData',
    //           textList: ['No booking items.'],
    //           button: []
    //         }
    //         break
    //       case 4000:
    //         this.displayDataList = []
    //         this.boardObj = { // Systerm error
    //           type: 'systemError',
    //           textList: ['Systerm error', 'Please try again or connect limosa_sup@icil.net'],
    //           button: []
    //         }
    //         break
    //     }
    //   }).catch((e) => {
    //     console.log(e)
    //     this.displayDataList = []
    //     this.boardObj = { // Systerm error
    //       type: 'systemError',
    //       textList: ['Systerm error', 'Please try again or connect limosa_sup@icil.net'],
    //       button: []
    //     }
    //   })
    //   this.saveQueryConditions()
    //   this.$store.commit('booking/setAllBookingList', this.displayDataList) // 本地存储booking list
    // },
    // saveQueryConditions () { // local storage save query conditions
    //   let data = {
    //     queryConditions: this.$store.getters['booking/getQueryConditions'],
    //     queryConditionParams: this.$store.getters['booking/getQueryConditionParams'],
    //     total: this.total
    //   }
    //   this.$store.commit('booking/setQueryConditions', data)
    // },
    logout () {
      this.$store.dispatch('changeEyeProtectionColor')
      document.getElementsByTagName('body')[0].style.setProperty('--label-font-color', '#fff')
      this.$store.dispatch('logoutServer').catch(e => {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: `Failed. Unexpected service error happens.
                Please re-upload files to try again.
                Or contact supporter for help: limosa_sup@icil.net`,
          type: 'error',
          showClose: true,
          duration: 5000,
          offset: 72
        })
      })
      this.$router.push({
        name: 'Login'
      })
      this.$store.state.booking.queryConditionParams = {
        bookingNo: '',
        awbNo: '',
        awbGroup: '',
        mawbNo: '',
        bookingType: '',
        bookingDateLoc: '',
        bookedBy: '',
        status: 'W',
        transferFlag: '',
        cargoStatus: '',
        origin: '',
        dest: '',
        customerCode: '',
        customerName: '',
        shipper: '',
        shipperName: '',
        cons: '',
        consName: '',
        pickupDeliveryMethod: '',
        estPickupDelDateLoc: '',
        estArrivalLeftWarehouseDateLoc: '',
        actPickupDelDateLoc: '',
        Carrier1st: '',
        FltNum1st: '',
        FltDateLoc1st: ''
      }
      this.$store.commit('booking/resetQueryConditions', this.$store.state.booking.queryConditionParams)
    },
    stretchAside () {
      this.$store.dispatch('changeisCollapse')
    },
    switchMode () {
      if (this.$store.state.eyeProtectionColor) {
        this.eyes = 'eyes'
        this.themeMode = 'Eye protection mode'
        this.$store.dispatch('changeEyeProtectionColor')
        document.getElementsByTagName('body')[0].style.setProperty('--label-font-color', '#fff')
      } else {
        this.eyes = 'eyes-close'
        this.themeMode = 'Normal mode'
        this.$store.dispatch('changeEyeProtectionColor')
        document.getElementsByTagName('body')[0].style.setProperty('--label-font-color', '#CCE8CF')
      }
    },
    switchRobot () {
      this.showChatbot = !this.showChatbot
    },
    hideChatbot (data) {
      this.showChatbot = data
    }
  }
}
</script>

<style lang="scss" scoped>
.header-display{
    line-height: 64px;
    display: flex;
    justify-content: space-between;
    .header-left{
      display: flex;
      .header-icon-box{
        display: flex;
        align-items: center;
        cursor: pointer;
        .header-icon{
          display: inline-block;
        }
      }
      .header-department {
        margin-left: 30px;
        // width: 100px;
      }
    }
    .header-right {
      display: flex;
      justify-content: center;
      align-items: center;
      .header-eyes{
        display: flex;
        align-items: center;
      }
      .header-eyes-icon{
        margin-right:28px;
        cursor: pointer;
      }
      .header-right-icon{
        margin-right:28px;
        cursor: pointer;
      }
      .header-right-avatar{
        display:flex;
        height: 32px;
        .el-avatar {
          width: 32px;
          height: 32px;
          line-height: 32px;
        }
      }
      .header-admin {
        margin-left: 8px;
        color: #666;
        cursor: pointer;
      }
    }
  }
</style>
