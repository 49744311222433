import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './styles.scss'
import locale from 'element-ui/lib/locale/lang/en'
import '@/assets/icons'
import './assets/icons/fonts/icomoon.css'
import Print from 'vue-print-nb'
import timeFormat from '@/utils/timeFormat'
import zhCnTimeFormat from '@/utils/zh-cn-TimeFormat'
import '@/utils/moment'
import moment from 'moment'
Vue.prototype.$moment = moment
// console.log(moment('2022-08-02T02:20:42.000+0000').format('YYYY-MM-DD hh:mm:ss'))
Vue.use(Print)
Vue.use(ElementUI, { locale })
Object.keys(timeFormat).forEach(key => {
  Vue.prototype[key] = timeFormat[key]
})
Object.keys(zhCnTimeFormat).forEach(key => {
  Vue.prototype[key] = zhCnTimeFormat[key]
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
