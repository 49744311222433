/*
* @Author: Dana Zhu
* @Date: 2022-10-24 10:31:01
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-11-21 16:19:58
>>>>>>> .r11156
*/
export function getPickupAddressId () { // 根据时间生成id
  var d = new Date()
  var year = d.getFullYear()
  var month = d.getMonth() + 1
  var date = d.getDate()
  // var day = d.getDay()
  var hours = d.getHours()
  var minutes = d.getMinutes()
  var seconds = d.getSeconds()
  // var ms = d.getMilliseconds()
  year = (year + '').substring(2)
  if (month <= 9) month = '0' + month
  if (date <= 9) date = '0' + date
  if (hours <= 9) hours = '0' + hours
  if (minutes <= 9) minutes = '0' + minutes
  if (seconds <= 9) seconds = '0' + seconds
  // if (ms <= 9) seconds = '0' + ms
  let num = Math.ceil(Math.random() * 1000)
  var id = year + month + date + hours + minutes + seconds + num
  return id
}
export function removePropertyOfNull (data) { // 过滤出对象中不为null的值
  const params = Object.keys(data)
    .filter(
      (key) => data[key] !== null && data[key] !== undefined && data[key] !== ''
    )
    .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})
  return params
}
export function filterMultiDataOfSingleCon (initArray, param, conditions) {
  // Filter multi-data by single conditions (awbType)
  let result = []
  for (let i = 0; i < conditions.length; i++) {
    result.push(initArray.find((item) => (item[param] === conditions[i])))
  }
  return result
}
export function adjugeReceivingDocument (value) { // 判断显示Receiving [table list]
  let receivingDoc = null
  if (value.requiredDocList && value.requiredDocList.length === 0 && value.receivingDocSummary.length === 0) {
    receivingDoc = 'Not Received'
  }
  if (value.receivingDocSummary.length > 0 && value.receivingDocSummary.every((item) => item.receive === true)) {
    receivingDoc = 'All Received'
  }
  if (value.requiredDocList && value.requiredDocList.length > 0 && value.receivingDocSummary.length > 0 && !value.receivingDocSummary.every((item) => item.receive === true)) {
    let totalRequireDocNum = value.requiredDocList.length
    let receivedDocNum = 0
    value.receivingDocSummary.forEach((item) => {
      // eslint-disable-next-line no-unused-expressions
      item.receive ? receivedDocNum++ : ''
    })
    receivingDoc = `Receiving ${receivedDocNum}/${totalRequireDocNum}`
  }
  return receivingDoc
}
export function addActionOfBookingList (status, transferFlag) { // Assign actions based on booking status
  let actionFrameList = []
  if (status === 'N') {
    actionFrameList = ['View Detail', 'Edit', 'Duplicate', 'Cancel']
  }
  if (status === 'B' && !transferFlag) {
    actionFrameList = [
      'View Detail',
      'Edit',
      'Transfer to AFSA',
      'Duplicate',
      'Cancel'
    ]
  }
  if (status === 'B' && transferFlag) {
    actionFrameList = ['View Detail', 'Edit', 'Duplicate', 'Cancel']
  }
  if (status === 'C' && !transferFlag) {
    actionFrameList = [
      'View Detail',
      'Transfer to AFSA',
      'Duplicate',
      'Cancel'
    ]
  }
  if (status === 'C' && transferFlag) {
    actionFrameList = ['View Detail', 'Duplicate', 'Cancel']
  }
  if (status === 'X') {
    actionFrameList = ['View Detail', 'Duplicate']
  }
  if (status === 'W') {
    actionFrameList = ['View Detail', 'Approve']
  }
  return actionFrameList
}
export function someArrayResult (value, arrayObj) {
  return arrayObj.some((el) => el === value)
}
export function getLocalTime (i) {
  // 得到标准时区的时间的函数
  // 参数i为时区值数字，比如北京为东八区则输进8,西5输入-5
  if (typeof i !== 'number') return
  var d = new Date()
  // 得到1970年一月一日到现在的秒数
  var len = d.getTime()
  // 本地时间与GMT时间的时间偏移差(注意：GMT这是UTC的民间名称。GMT=UTC）
  var offset = d.getTimezoneOffset() * 60000
  // 得到现在的格林尼治时间
  var utcTime = len + offset
  // 转为标准时间
  var standardTime = new Date(utcTime + 3600000 * i)
  var datetime = new Date(standardTime)
  var day = datetime.getDate()
  if (day >= 1 && day <= 9) {
    day = '0' + day
  }
  var month = datetime.getMonth() + 1
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  var hour = datetime.getHours()
  if (hour >= 1 && hour <= 9) {
    hour = '0' + hour
  }
  var minutes = datetime.getMinutes()
  if (minutes >= 1 && minutes <= 9) {
    minutes = '0' + minutes
  }
  var seconds = datetime.getSeconds()
  if (seconds >= 1 && seconds <= 9) {
    seconds = '0' + seconds
  }
  var dateTimeFormate = `${day}/${month}/${datetime.getFullYear()} ${hour}:${minutes}:${seconds}`
  return dateTimeFormate
}
