/* * @Author: Dana Zhu  * @Date: 2022-10-27 12:24:38  * @Last Modified by:   Dana Zhu  * @Last Modified time: 2022-10-27 12:24:38  */
const headerSettingKey = 'limosa-booking-header-setting'
const queryValueKey = 'limosa-booking-query-value'
const bookingListKey = 'limosa-booking-list'
// const switchOfficeCode = 'limosa-switch-officeCode'

export function getHeaderSetting () {
  let headerSetting = window.localStorage.getItem(headerSettingKey)
  return JSON.parse(headerSetting)
}

export function setHeaderSetting (val) {
  window.localStorage.setItem(headerSettingKey, JSON.stringify(val))
}

export function removeHeaderSettingkey () {
  window.localStorage.removeItem(headerSettingKey)
}

export function getQueryValue () {
  let queryConditions = window.localStorage.getItem(queryValueKey)
  return JSON.parse(queryConditions)
}

export function setQueryValue (val) {
  window.localStorage.setItem(queryValueKey, JSON.stringify(val))
}

export function removeQueryValuekey () {
  window.localStorage.removeItem(queryValueKey)
}

export function getBookingList () {
  let data = window.sessionStorage.getItem(bookingListKey)
  return JSON.parse(data)
}

export function setBookingList (val) {
  window.sessionStorage.setItem(bookingListKey, JSON.stringify(val))
}
