import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
import Login from '../views/login/index.vue'
import Home from '../views/home.vue'
import { EDI } from './module/edi'
import { CargoReceiving } from './module/cargo-receiving'
// import { Booking } from './module/booking'
// import { preAllocatedHawb } from './module/pre-allocated-hawb'
import { setMenukeyByRole } from '@/utils/route'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
/**
 * 权限控制!!!!
 * 每个页面路由需要加上 meta: { resourceKey: '', available: false } 信息
 * resourceKey: permission 信息登录返回的数据，展示只做到菜单项的控制，不支持表单的控制，如果不是菜单项 resourceKey 需要和父级同名
 * available: 默认 false 改路由是否可以跳转，在路由守卫进行控制跳转时会用到，还有菜单的显示
 */
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      available: true // 默认可以跳转
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/welcome', // 不能设置重定向
    meta: {
      available: true // 默认可以跳转
    },
    children: [
      {
        path: '/welcome',
        name: 'Welcome',
        component: () => import('@/components/home/welcome.vue'),
        meta: {
          available: false, // 默认可以跳转
          resourceKey: 'welcome'
        }
      },
      {
        path: '/afsa-data',
        name: 'afsa-data',
        redirect: '/afsa-data/afsa-data-list',
        component: () => import('../views/afsa-data/index.vue'),
        meta: {
          title: 'Load AFSA Data',
          activeMenu: '/afsa-data',
          activeIcon: 'Inventory - focus',
          defaultIcon: 'Inventory',
          resourceKey: 'menu_loadAfsaData',
          available: false
        },
        children: [
          {
            path: '/afsa-data/afsa-data-list',
            name: 'afsa-data-list',
            meta: {
              resourceKey: 'menu_loadAfsaData',
              available: false
            },
            component: () =>
              import('../views/afsa-data/afsa-data-list/index.vue')
          },
          {
            path: '/afsa-data/qurey-result',
            name: 'qurey-result',
            meta: {
              resourceKey: 'menu_loadAfsaData',
              available: false
            },
            component: () =>
              import('../views/afsa-data/afsa-data/qurey-result.vue')
          },
          {
            path: '/afsa-data/load-data-result',
            name: 'load-data-result',
            meta: {
              resourceKey: 'menu_loadAfsaData',
              available: false
            },
            component: () =>
              import('../views/afsa-data/afsa-data/load-result.vue')
          }
        ]
      },
      {
        path: '/declaration',
        name: 'declaration',
        redirect: '/declaration/declaration-list',
        component: () => import('../views/declaration/index.vue'),
        meta: {
          title: 'Pre-declaration',
          activeMenu: '/declaration',
          activeIcon: 'inbound - focus',
          defaultIcon: 'inbound',
          resourceKey: 'menu_pre-declaration',
          available: false
        },
        children: [
          {
            path: '/declaration/declaration-list',
            name: 'declaration-list',
            meta: {
              resourceKey: 'menu_pre-declaration',
              available: false
            },
            component: () =>
              import('../views/declaration/declaration-list/index.vue')
          },
          {
            path: '/declaration/create',
            name: 'Create',
            meta: {
              resourceKey: 'menu_pre-declaration',
              available: false
            },
            component: () =>
              import('../views/declaration/declaration/create.vue')
          },
          {
            path: '/declaration/check',
            name: 'check',
            meta: {
              resourceKey: 'menu_pre-declaration',
              available: false
            },
            component: () =>
              import('../views/declaration/declaration/check.vue')
          },
          {
            path: '/declaration/query-result',
            name: 'query-result',
            meta: {
              resourceKey: 'menu_pre-declaration',
              available: false
            },
            component: () =>
              import('../views/declaration/declaration/query-result.vue')
          }
        ]
      },
      {
        path: '/cargo-tracking',
        name: 'cargo-tracking',
        redirect: '/cargo-tracking/cargo-tracking-query',
        component: () => import('../views/cargo-tracking/index.vue'),
        meta: {
          title: 'Cargo Tracking',
          activeMenu: '/cargo-tracking',
          activeIcon: 'filter-focus',
          defaultIcon: 'filter',
          resourceKey: 'menu_cargoTracking',
          available: false
        },
        children: [
          {
            path: '/cargo-tracking/cargo-tracking-query',
            name: 'cargo-tracking-query',
            meta: {
              resourceKey: 'menu_cargoTracking',
              available: false
            },
            component: () =>
              import('../views/cargo-tracking/cargo-tracking-query/index.vue')
          },
          {
            path: '/cargo-tracking/tracking-result',
            name: 'trackingResult',
            meta: {
              resourceKey: 'menu_cargoTracking',
              available: false
            },
            component: () =>
              import(
                '../views/cargo-tracking/cargo-tracking/tracking-result.vue'
              )
          }
        ]
      },
      ...EDI,
      ...CargoReceiving
      // ...Booking,
      // ...preAllocatedHawb
    ]
  }
]

setMenukeyByRole(routes[2].children)
const router = new VueRouter({
  routes
})
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    const token = getToken()
    if (!token) {
      next('/login')
    }
    // 跳转白名单
    const names = ['Welcome']
    // console.log('to.meta.available', to, to.meta, to.meta.available)
    if (to.meta.available || names.includes(to?.name)) {
      next()
    } else {
      console.log('Jump failure')
      next('/welcome')
    }
  }
})

export default router
