/*
 * @Author: Dana
 * @Date: 2022-09-15 13:54:29
 * @Last Modified by: Dana Zhu
 * @Last Modified time: 2022-12-02 16:54:13
 */
import request from '@/utils/request'
// import { getOfficeCode } from '../../utils/auth'

const INITBOOKINF_URL = '/limosa/service/initBooking' // Provide by Frank
const CREATEBOOKINGBYUI_URL = '/limosa/service/createBookingAndGenerateTask' // Provide by Frank
const CREATEDRAFTBOOKING_URL = '/limosa/service/createDraftBooking' // Provide by Frank
const QUERYBOOKINGLIST_URL = '/limosa/service/queryBooking' // Provide by Frank
const QUERYBOOKINGDETAIL_URL = '/limosa/service/queryBookingDetail' // Provide by Frank
const UPDATEBOOKING_URL = '/limosa/service/updateBooking' // Provide by Frank
const CANCELBOOKING_URL = '/limosa/service/cancelBooking' // Provide by Frank
const DUPLICATEBOOKING_URL = '/limosa/service/duplicateBooking' // Provide by Frank
const QUERYINITOPTIONSDATA_URL = '/limosa/service/initBookingDependency' // Provide by Frank
const TRANSFERBOOKINGS_URL = '/limosa/service/transferBooking' // Provide by Tommy
const QUERYHISTORYACTION_URL = '/limosa/service/queryOpHistoryByBookingNo' // Provide by Zero
const DOWNLOADDOCFILE_URL = '/limosa/service/downloadBookingDoc' // Provide by Lucy
const APPROVEBOOKING_URL = '/limosa/service/approveBooking' // Provide by Frank
const PRINTSLI_URL = '/limosa/service/report/generateSLI' // Provide by Lucky
// const UPLOADSLIANDCREATE_URL = '/limosa/service/uploadSLIExcel' // Provide by Frank
const DOWNLOADSLITEMPLATE_URL = '/limosa/service/downloadSLIExcel' // Provide by Frank

export function initBooking () {
  // getOfficeCode() 暂时注销
  let offest = '' + new Date()
  let gmt = offest.indexOf('GMT')
  let timezone = offest.substring(gmt + 0, gmt + 8)
  return request({
    method: 'post',
    url: INITBOOKINF_URL + '?office=EXHKG' + '&timezone=' + timezone,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    loading: true
  })
}

export function createBookingByUI (data) {
  return request({
    method: 'post',
    url: CREATEBOOKINGBYUI_URL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: data,
    loading: true
  })
}

export function createDraftBooking (data, loading) {
  return request({
    method: 'post',
    url: CREATEDRAFTBOOKING_URL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: data,
    loading: loading
  })
}

export function queryBookingList (data) {
  return request({
    method: 'post',
    url: QUERYBOOKINGLIST_URL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: data,
    loading: true
  })
}

export function queryBookingDetail (data) {
  return request({
    method: 'post',
    url: QUERYBOOKINGDETAIL_URL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: data,
    loading: true
  })
}

export function updateBooking (data) {
  return request({
    method: 'post',
    url: UPDATEBOOKING_URL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: data,
    loading: true
  })
}

export function transferBookings (data) {
  return request({
    method: 'post',
    url: TRANSFERBOOKINGS_URL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: data,
    loading: true
  })
}

export function cancelBooking (data) {
  return request({
    method: 'post',
    url: CANCELBOOKING_URL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: data,
    loading: true
  })
}

export function duplicateBooking (bookingNo) {
  return request({
    method: 'post',
    url: DUPLICATEBOOKING_URL + '?bookingNo=' + bookingNo,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    loading: true
  })
}

export function queryOptionsData () {
  let offest = '' + new Date()
  let gmt = offest.indexOf('GMT')
  let timezone = offest.substring(gmt + 0, gmt + 8)
  return request({
    method: 'post',
    url: QUERYINITOPTIONSDATA_URL + '?office=EXHKG' + '&timezone=' + timezone,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    loading: false
  })
}

export function queryHistoryActions (bookingNo) {
  return request({
    method: 'get',
    url: QUERYHISTORYACTION_URL + '?bookingNo=' + bookingNo,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    loading: false
  })
}

export function downloadDocFile (docURL) {
  return request({
    method: 'post',
    url: DOWNLOADDOCFILE_URL + '?docURL=' + docURL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    loading: false
  })
}

export function approveBooking (data) {
  return request({
    method: 'post',
    url: APPROVEBOOKING_URL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: data,
    loading: true
  })
}

export function printSLIReport (bookingNo) {
  return request({
    method: 'post',
    url: PRINTSLI_URL + '?bookingNo=' + bookingNo + '&office=EXHKG',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    loading: true
  })
}

// export function uploadSLI (docURL) {
//   return request({
//     method: 'post',
//     url: UPLOADSLIANDCREATE_URL,
//     headers: {
//       'Content-Type': 'application/json; charset=utf-8'
//     },
//     data: docURL,
//     loading: true
//   })
// }

export function downloadSLI () {
  return request({
    method: 'post',
    url: DOWNLOADSLITEMPLATE_URL,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/vnd.ms-excel; charset=utf-8'
    },
    loading: true
  })
}
