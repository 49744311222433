/* * @Author: Mini Wei  * @Date: 2022-11-03 14:27:24  * @Last Modified by:   Dana Zhu  * @Last Modified time: 2022-11-03 14:27:24  */import Vue from 'vue'
import Vuex from 'vuex'
import { Base64 } from 'js-base64'
import { loginServer, logoutServer } from '@/api/login/login-api'
import { setToken, setName, removeToken, removeName, setOfficeCode, removeOfficeCode, removeMenukey } from '@/utils/auth'
import common from '@/store/modules/common'
import booking from '@/store/modules/booking/booking'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapse: false, // 菜单折叠
    eyeProtectionColor: false, // 切换主题
    userAvatarUrl: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png', // 用户头像
    queryResultAFsa: { // afsa的result
      currentPage: 1,
      datas: null,
      pageSize: 10,
      totalNum: 0
    },
    loadResultAFsa: {
      currentPage: 1,
      datas: null,
      pageSize: 10,
      totalNum: 0
    },
    queryResultDeclaration: {
      currentPage: 1,
      datas: [],
      pageSize: 10,
      totalNum: 0
    },
    refreshMenu: false
  },
  getters: {
    refreshMenu: state => state.refreshMenu
    // getQueryConditions: state => state.booking.queryConditions,
    // getQueryConditionParams: state => state.booking.queryConditionParams
  },
  mutations: {
    changeisCollapse () {
      this.state.isCollapse = !this.state.isCollapse
    },
    changeEyeProtectionColor () {
      this.state.eyeProtectionColor = !this.state.eyeProtectionColor
    },
    setName (state, params) {
      state.userName = params
    },
    changequeryResultAFsa (state, params) {
      state.queryResultAFsa.currentPage = params.currentPage
      state.queryResultAFsa.datas = params.datas
      state.queryResultAFsa.pageSize = params.pageSize
      state.queryResultAFsa.totalNum = params.totalNum
    },
    changeLoadDataResultAFsa (state, params) {
      state.loadResultAFsa.currentPage = params.currentPage
      state.loadResultAFsa.datas = params.datas
      state.loadResultAFsa.pageSize = params.pageSize
      state.loadResultAFsa.totalNum = params.totalNum
    },
    changeQueryResultDeclaration (state, params) {
      state.queryResultDeclaration.datas = []
      state.queryResultDeclaration.currentPage = params.pageNum
      state.queryResultDeclaration.pageSize = params.pageSize
      state.queryResultDeclaration.totalNum = params.total
      params.records.forEach(element => {
        if (element.predecData) {
          state.queryResultDeclaration.datas.push({
            preDeclarationId: element.preDeclarationId,
            cargoType: element.predecData.cargoType,
            uld: element.unitLoadDevice,
            mawb: element.mawbNo,
            dest: element.predecData.destination,
            flight: element.predecData.flightNo,
            flightDate: element.predecData.flightDate,
            createdBy: element.predecData.createdBy,
            submitTime: element.submitDtLoc,
            status: element.result,
            rclAvailable: element.rclAvailable
          })
        }
      })
    },
    changRefreshMenu (state) {
      state.refreshMenu = !state.refreshMenu
    }
  },
  actions: {
    // 护眼模式
    changeisCollapse () {
      this.commit('changeisCollapse')
    },
    changeEyeProtectionColor () {
      this.commit('changeEyeProtectionColor')
    },
    // 登录
    login ({ commit }, res) {
      return new Promise((resolve, reject) => {
        const params = {
          username: res.username,
          password: Base64.encode(res.password)
        }
        loginServer(params).then(res => {
          if (res.data.code === 200) {
            setToken(res.data.data.token)
            setName(res.data.data.username)
            setOfficeCode(res.data.data.officeCode)
            resolve(res.data.data.roles)
          } else {
            if (res.data.message !== null || res.data.message !== '') {
              reject(res.data.message)
            }
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('setName', '')
        removeToken()
        removeName()
        removeOfficeCode()
        removeMenukey()
        resolve()
        // clearLocalStorage()
      })
    },
    logoutServer ({ commit }) {
      return new Promise((resolve, reject) => {
        logoutServer().then(res => {
          if (res.data.code !== 200) {
            if (res.data.message !== null || res.data.message !== '') {
              reject(res.data.message)
            }
          }
        }).catch(e => {
          reject(e)
        })
      })
    },
    // QueryResultAFSA
    changequeryResultAFsa ({ commit }, res) {
      commit('changequeryResultAFsa', res)
    },
    changeLoadDataResultAFsa ({ commit }, res) {
      commit('changeLoadDataResultAFsa', res)
    },
    changeQueryResultDeclaration ({ commit }, res) {
      commit('changeQueryResultDeclaration', res)
    }
  },
  modules: {
    common,
    booking
  }
})
