<template>
  <!-- <div v-show="showProgress" v-loading="showProgress" class="swift-loading">
    <el-progress :percentage="percentage"></el-progress>
  </div> -->
  <div v-show="isLoading" v-loading.fullscreen.lock="isLoading" class="swift-loading"></div>
</template>

<script>

export default {
  name: 'Loading',
  components: {},
  data () {
    return {
      startTimer: '',
      showProgress: false,
      percentage: 0
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.common.isLoading
    }
  },
  watch: {
    // isLoading (newVal, oldVal) {
    //   if (newVal) {
    //     this.stop()
    //   } else {
    //     this.move()
    //   }
    // },
    show (newVal, oldVal) {
      if (newVal) {
        this.showProgress = true
        this.percentage = 0
        this.$nextTick(() => {
          this.startProgress()
        })
      } else {
        this.startTimer && clearInterval(this.startTimer)
        this.percentage = 100
        this.$nextTick(() => {
          this.showProgress = false
        })
      }
    }
  },
  beforeDestroy () {
    this.startTimer && clearInterval(this.startTimer)
  },
  methods: {
    stop () { // 禁止页面滑动
      var mo = function (e) { e.preventDefault() }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false) // 禁止页面滑动
    },
    move () { // 启动页面滑动
      var mo = function (e) { e.preventDefault() }
      document.body.style.overflow = ''
      document.addEventListener('touchmove', mo, false) // 禁止页面滑动
    },
    startProgress () {
      this.startTimer = setInterval(() => {
        this.percentage += 9
        if (this.percentage >= 99) {
          this.percentage = 99
          clearInterval(this.startTimer)
        }
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.swift-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.el-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px
}
</style>
