<template>
  <el-submenu
    v-if="routeItem.meta.available"
    :popper-append-to-body="false"
    :index="routeItem.path">
    <template slot="title">
      <svg-icon
        v-if="routeItem.meta.activeIcon || routeItem.meta.defaultIcon"
        :icon-class="findElem(routeItem.path, actived) != -1 ? routeItem.meta.activeIcon : routeItem.meta.defaultIcon"
        style="vertical-align: -6px; margin-right: 8px"
      />
      <span
        slot="title"
        class="menu-title"
        :class="findElem(routeItem.path, actived) != -1 ? 'nav-actived' : ''"
        >
        {{ routeItem.meta.title }}
      </span>
    </template>
    <template v-for="(item, index) in routerMenu">
      <component
        v-if="item.meta.available"
        :is="item.meta.showChildren ? 'subMenu' : 'menuItem'"
        :routerMenu="item.children"
        :routeItem="item"
        :actived="actived"
        :findElem="findElem"
        :showIcon="false"
        :key="index">
      </component>
    </template>
  </el-submenu>
</template>
<script>
import menuItem from './menu-item.vue'
export default {
  name: 'subMenu',
  components: {
    menuItem
  },
  props: {
    routerMenu: {
      type: Array,
      default: () => []
    },
    routeItem: {
      type: Object
    },
    actived: {
      type: String,
      default: ''
    },
    findElem: {
      type: Function
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-title {
  font-size: 16px;
}
.el-submenu {
  background-color: $bg-color;
}
.nav-actived {
  color: #1890ff !important;
}
// ::v-deep {
//   .is-active {
//     color: #303133;
//   }
// }
</style>
