<template>
  <div class="page-wrap">
    <!-- 咨询框头部 -->
    <div class="page-header">
      <span><svg-icon icon-class="other" style="width:30px;height:30px;cursor: pointer;"/></span>
      <span>Robot online solution</span>
      <span @click="hideChatbot"><svg-icon icon-class="hide" style="width:30px;height:30px;cursor: pointer;"/></span>
    </div>
    <div class="page-content">
      <div v-if="chatLoaded" class="common_chat-main" ref="common_chat_main">
        <div class="common_chat-main-content">
          <div class="inner">
            <div v-for="(item ,index) in chatInfoEn.msgList" :key="index">
              <!-- 系统消息 -->
              <div v-if="item.role=='sys'" class="item sys">
                <!--文本类型 -->
                <div v-if="item.contentType=='text'" class="text-content">
                  <p>{{item.content}}</p>
                </div>
              </div>
              <!-- 用户，机器人 -->
              <div v-else class="item" :class="{ sender: item.role !== 'robot', receiver: item.role === 'robot' }">
                <div class="info-wrapper" :class="item.state">
                  <!-- 头像 -->
                  <div class="avatar-wrapper">
                    <img class="kf-img" :src="item.avatarUrl" />
                    <!-- <img class="kf-img" src="../../assets/index/robot.png" /> -->
                  </div>
                  <!-- 文本类型 -->
                  <div v-if="item.contentType=='text'" class="item-content common_chat_emoji-wrapper-global">
                    <p class="text">{{item.content}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-input">
      <!-- 聊天输入框 -->
      <el-input v-model="chatInfoEn.inputContent" ref="common_chat_input" autofocus @keyup.enter.native="sendText"></el-input>
      <!-- 发送按钮 -->
      <el-button type="primary" size="small" class="send-btn" :class="chatInfoEn.state" @click="sendText()" :disabled="chatInfoEn.inputContent.length === 0">发送</el-button>
    </div>
  </div>
</template>

<script>
import robot from '../../assets/index/robot.png'

export default {
  name: 'chatbot-page',
  props: {
    showChatbot: {
      typeof: Boolean,
      default: false
    }
  },
  data () {
    return {
      chatInfoEn: {
        required: true,
        type: Object,
        inputContent: '', // 本次输入框的消息内容
        msgList: [{
          role: 'sys',
          contentType: 'text',
          content: 'The robot serves you all the way'
        }, {
          role: 'robot',
          avatarUrl: robot,
          contentType: 'text',
          content: 'Hi, may I help you?'
        }] // 消息集合
      },
      chatLoaded: true // chat是否已加载完毕
    }
  },
  methods: {
    hideChatbot () {
      this.$emit('hide-Chatbot', false)
    },
    sendMsg (msg) {
      this.chatInfoEn.msgList.push(msg)
      this.goEnd()
    },
    goEnd  () {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.common_chat_main.scrollTop = this.$refs.common_chat_main.scrollHeight
        }, 100)
      })
    },
    sendText () {
      if (this.chatInfoEn.inputContent.length === '') {
        return
      }
      var msgContent = this.chatInfoEn.inputContent
      this.chatInfoEn.inputContent = ''
      this.sendMsg({
        role: 'user',
        avatarUrl: this.$store.state.userAvatarUrl,
        contentType: 'text',
        content: msgContent
      })
      const msgContentLowercase = msgContent.toLowerCase()
      if (msgContentLowercase.search('query') !== -1) {
        this.reply({
          role: 'robot',
          avatarUrl: robot,
          contentType: 'text',
          content: 'Each main page has a query function. Click and enter the content to query.'
        })
      } else {
        this.reply({
          role: 'robot',
          avatarUrl: robot,
          contentType: 'text',
          content: 'No search results found, please re-enter...Or contact supporter for help: xxxx@mail.icil.net.'
        })
      }
    },
    reply (msg) {
      setTimeout(() => {
        this.sendMsg(msg)
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrap {
  position: absolute;
  top: 64px;
  right: 100px;
  width: 320px;
  height: 620px;
  // border: 1px solid #e5e5e5;
  z-index: 10;
  border-radius: 8px;
  background-color: #fff;
  -webkit-box-shadow: 0px 1px 5px #c8c8c8;
  -moz-box-shadow: 0px 1px 5px #c8c8c8;
  box-shadow: 0px 1px 5px #c8c8c8;
  .page-header {
    height: 56px;
    padding: 0 12PX;
    line-height: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      display: inline-block;
    }
  }
  .page-content {
    height: 510px;
    background-color: #f7f7f7;
    .common_chat-main {
      position: relative;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      .common_chat-main-content {
        position: absolute;
        width: 100%;
        height: 100%;
        & > .inner {
          padding-bottom: 20px;
          .item {
            clear: both;
            overflow: hidden;
          }
          .sys {
            color: #b0b0b0;
            font-size: 12px;
            text-align: center;
            .text-content {
              padding-top: 20px;
            }
            .myd-content {
              .desc {
                margin-top: 18px;
              }
              .text {
                color: #3e3e3e;
                margin-top: 12px;
              }
              .remark {
                margin-top: 10px;
              }
            }
          }
          .receiver,.sender {
            margin-top: 18px;
            font-size: 12px;
            .avatar-wrapper {
              float: left;
              .kf-img {
                width: 40px;
                height: 40px;
                border-radius: 50px;
              }
            }
            .info-wrapper {
              position: relative;
              text-align: left;
              font-size: 12px;
              .item-content {
                position: relative;
                max-width: 330px;
                color: #3e3e3e;
                font-size: 13px;
                border-radius: 3px;
                .text {
                  line-height: 1.8;
                  white-space: normal;
                  word-wrap: break-word;
                  word-break: break-all;
                  padding: 10px 12px;
                  margin: 0;
                }
                .img {
                  max-width: 320px;
                  max-height: 240px;
                  white-space: normal;
                  word-wrap: break-word;
                  word-break: break-all;
                  padding: 5px;
                  cursor: pointer;
                }
              }
            }
          }
          .item.receiver {
            margin-left: 5px;
            .avatar-wrapper {
              margin-right: 15px;
            }
            .info-wrapper {
              .item-content {
                float: left;
                color: #000000;
                background-color: #f9fbfc;
                border: 1px solid #ccc;
                max-width: 200px;
                &::before {
                  position: absolute;
                  top: -1px;
                  left: -10px;
                  width: 0px;
                  height: 0px;
                  content: '';
                  border-top: 0px;
                  border-right: 10px solid #ccc;
                  border-bottom: 5px solid transparent;
                  border-left: 0px;
                }
              }
            }
          }
          .item.sender {
            margin-right: 5px;
            .avatar-wrapper {
              float: right;
              margin-left: 15px;
            }
            .info-wrapper {
              float: right;
              .item-content {
                float: right;
                background: #0095ff;
                border: 1px solid #0095ff;
                color: #ffffff;
                max-width: 200px;
                &::before {
                  position: absolute;
                  top: -1px;
                  right: -10px;
                  width: 0px;
                  height: 0px;
                  content: '';
                  border-top: 0px;
                  border-right: 0px;
                  border-bottom: 5px solid transparent;
                  border-left: 10px solid #0095ff;
                }
              }
            }
          }
        }
      }
    }
  }
  .page-input {
    height: 34px;
    padding: 10px;
    line-height: 0px;
    // background-color: #e3edff;
    display: flex;
    justify-content: space-between;
    .send-btn {
      float: right;
      // margin-right: 16px;
      &.off,
      &.end {
        background-color: #ccc;
        border-color: #ccc;
      }
    }
    ::v-deep{
      .el-input__inner{
        border: 0;
      }
    }
  }
}
</style>
