<template>
  <el-container>
    <el-aside :class="this.$store.state.isCollapse ? 'asideWidth':'asideWidth2'">
      <Aside></Aside>
    </el-aside>
    <el-container>
      <el-header height="64px" :class="this.$store.state.isCollapse ? 'header-width ': 'header-width2'">
        <Header></Header>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// @ is an alias to /src
import Aside from '../components/home/aside.vue'
import Header from '../components/home/header.vue'
export default {
  name: 'Home',
  components: {
    Aside,
    Header
  }
}
</script>

<style lang="scss" scoped>
.asideWidth{
  width: 64px !important;
  transition: width 0.5s;
}
.asideWidth2{
  width: 256px !important;
  transition: width 0.5s;
}
.header-width{
  width: calc(100% - 64px);
  transition: width 0.5s;
}
.header-width2{
  width: calc(100% - 256px);
  transition: width 0.5s;
}
.el-aside {
  background-color: $bg-color;
  height: 100vh;
}
.el-header{
  position: fixed;
  z-index: 4;
  background-color: $bg-color;
  box-shadow: 2px 0px 8px 0px #00000014;
}
.el-main {
  margin-top: 64px;
  display: block;
  flex: 1;
  flex-basis: auto;
  overflow: auto;
  box-sizing: border-box;
  padding: 0px;
  background-color: $bg-color;
  overflow: hidden;
  height: 100%;
}
</style>
