export const CargoReceiving = [
  {
    path: '/cargo-receiving',
    name: 'cargo-receiving',
    component: () => import('../../views/cargo-receiving/index.vue'),
    meta: {
      title: 'Cargo Receiving',
      activeMenu: '/cargo-receiving',
      activeIcon: 'edi-focus',
      defaultIcon: 'edi',
      showChildren: true, // 是否有子菜单
      resourceKey: 'menu_cargo receiving',
      available: false
    },
    children: [
      {
        path: '/cargo-receiving/security-screening',
        name: 'security-screening',
        redirect: '/cargo-receiving/security-screening/list',
        component: () => import('../../views/cargo-receiving/security-screening/index.vue'),
        meta: {
          title: 'Security Screening',
          activeMenu: '/cargo-receiving',
          showChildren: false, // 是否有子菜单
          resourceKey: 'menu_security screening',
          available: false
        },
        children: [
          {
            path: '/cargo-receiving/security-screening/list',
            name: 'security-screening-list',
            meta: {
              resourceKey: 'menu_security screening',
              available: false
            },
            component: () => import('../../views/cargo-receiving/security-screening/list/index.vue')
          },
          {
            path: '/cargo-receiving/security-screening/record-screening-result',
            name: 'record-screening-result',
            meta: {
              resourceKey: 'menu_security screening',
              available: false
            },
            component: () => import('../../views/cargo-receiving/security-screening/record-screening-result/index.vue')
          }
        ]
      }
    ]
  }
]
