
const awbGroupKey = 'limosa-booking-awbGroup'
const awbTypeKey = 'limosa-booking-awbType'
const bookingTypeKey = 'limosa-booking-bookingType'
const businessServiceKey = 'limosa-booking-businessService'
const cargoStatusKey = 'limosa-booking-cargoStatus'
const cargoTypeKey = 'limosa-booking-cargoType'
const dimensionUnitKey = 'limosa-booking-dimensionUnit'
const docNameKey = 'limosa-booking-docName'
const exportImportFlagKey = 'limosa-booking-exportImportFlag'
const frtPayTypeKey = 'limosa-booking-frtPayType'
const incotermKey = 'limosa-booking-incoterm'
const isEComKey = 'limosa-booking-isECom'
const isReceivedKey = 'limosa-booking-isReceived'
const otherPayTypeKey = 'limosa-booking-otherPayType'
const packageUnitKey = 'limosa-booking-packageUnit'
const pickupDeliveryMethodKey = 'limosa-booking-pickupDeliveryMethod'
const sentMethodKey = 'limosa-booking-sentMethod'
const serviceLevelKey = 'limosa-booking-serviceLevel'
const serviceScopeKey = 'limosa-booking-serviceScope'
const serviceTypeKey = 'limosa-booking-serviceType'
const showNotifyPartyOnAWBKey = 'limosa-booking-showNotifyPartyOnAWB'
const bookingStatusKey = 'limosa-booking-status'
const transferFlagKey = 'limosa-booking-transferFlag'
const valueAddedServiceCodeKey = 'limosa-booking-valueAddedServiceCode'
const volumeUnitKey = 'limosa-booking-volumeUnit'
const weightUnitKey = 'limosa-booking-weightUnit'
const transferKey = 'limosa-booking-transferRecord'

export function getAwbGroup () {
  let awbGroup = window.localStorage.getItem(awbGroupKey)
  let awbGroupList = JSON.parse(awbGroup)
  awbGroupList.some((el, index) => {
    if (el.code === 'M') {
      awbGroupList.splice(index, 1)
    }
  })
  return awbGroupList
}

export function setAwbGroup (val) {
  window.localStorage.setItem(awbGroupKey, JSON.stringify(val))
}

export function removeAwbGroupkey () {
  window.localStorage.removeItem(awbGroupKey)
}

export function getAwbType () {
  let val = window.localStorage.getItem(awbTypeKey)
  return JSON.parse(val)
}

export function setAwbType (awbType) {
  window.localStorage.setItem(awbTypeKey, JSON.stringify(awbType))
}

export function removeAwbTypekey () {
  window.localStorage.removeItem(awbTypeKey)
}

export function getBookingType () {
  let val = window.localStorage.getItem(bookingTypeKey)
  return JSON.parse(val)
}

export function setBookingType (bookingType) {
  bookingType.forEach((el) => {
    if (el.code === 'N') {
      el.description = 'Normal'
    }
    if (el.code === 'A') {
      el.description = 'Agent'
    }
  })
  window.localStorage.setItem(bookingTypeKey, JSON.stringify(bookingType))
}

export function removeBookingTypekey () {
  window.localStorage.removeItem(bookingTypeKey)
}

export function getBusinessService () {
  let val = window.localStorage.getItem(businessServiceKey)
  return JSON.parse(val)
}

export function setBusinessService (businessService) {
  window.localStorage.setItem(
    businessServiceKey,
    JSON.stringify(businessService)
  )
}

export function removeBusinessServicekey () {
  window.localStorage.removeItem(businessServiceKey)
}

export function getCargoStatus () {
  let val = window.localStorage.getItem(cargoStatusKey)
  return JSON.parse(val)
}

export function setCargoStatus (cargoStatus) {
  window.localStorage.setItem(cargoStatusKey, JSON.stringify(cargoStatus))
}

export function removeCargoStatuskey () {
  window.localStorage.removeItem(cargoStatusKey)
}

export function getCargoType () {
  let val = window.localStorage.getItem(cargoTypeKey)
  return JSON.parse(val)
}

export function setCargoType (cargoType) {
  window.localStorage.setItem(cargoTypeKey, JSON.stringify(cargoType))
}

export function removeCargoTypeKey () {
  window.localStorage.removeItem(cargoTypeKey)
}

export function getDimensionUnit () {
  let val = window.localStorage.getItem(dimensionUnitKey)
  return JSON.parse(val)
}

export function setDimensionUnit (dimensionUnit) {
  window.localStorage.setItem(dimensionUnitKey, JSON.stringify(dimensionUnit))
}

export function removeDimensionUnitKey () {
  window.localStorage.removeItem(dimensionUnitKey)
}

export function getDocName () {
  let val = window.localStorage.getItem(docNameKey)
  return JSON.parse(val)
}

export function setDocName (docName) {
  window.localStorage.setItem(docNameKey, JSON.stringify(docName))
}

export function removeDocNameKey () {
  window.localStorage.removeItem(docNameKey)
}

export function getExportImportFlag () {
  let val = window.localStorage.getItem(exportImportFlagKey)
  return JSON.parse(val)
}

export function setExportImportFlag (exportImportFlag) {
  window.localStorage.setItem(
    exportImportFlagKey,
    JSON.stringify(exportImportFlag)
  )
}

export function removeExportImportFlagKey () {
  window.localStorage.removeItem(exportImportFlagKey)
}

export function getFrtPayType () {
  let val = window.localStorage.getItem(frtPayTypeKey)
  return JSON.parse(val)
}

export function setFrtPayType (frtPayType) {
  window.localStorage.setItem(frtPayTypeKey, JSON.stringify(frtPayType))
}

export function removeFrtPayTypeKey () {
  window.localStorage.removeItem(frtPayTypeKey)
}

export function getIncoterm () {
  let val = window.localStorage.getItem(incotermKey)
  return JSON.parse(val)
}

export function setIncoterm (incoterm) {
  window.localStorage.setItem(incotermKey, JSON.stringify(incoterm))
}

export function removeIncotermKey () {
  window.localStorage.removeItem(incotermKey)
}

export function getIsECom () {
  let val = window.localStorage.getItem(isEComKey)
  return JSON.parse(val)
}

export function setIsECom (isECom) {
  window.localStorage.setItem(isEComKey, JSON.stringify(isECom))
}

export function removeIsEComKey () {
  window.localStorage.removeItem(isEComKey)
}

export function getIsReceived () {
  let val = window.localStorage.getItem(isReceivedKey)
  return JSON.parse(val)
}

export function setIsReceived (isReceived) {
  window.localStorage.setItem(isReceivedKey, JSON.stringify(isReceived))
}

export function removeIsReceivedKey () {
  window.localStorage.removeItem(isReceivedKey)
}

export function getOtherPayType () {
  let val = window.localStorage.getItem(otherPayTypeKey)
  return JSON.parse(val)
}

export function setOtherPayType (otherPayType) {
  window.localStorage.setItem(otherPayTypeKey, JSON.stringify(otherPayType))
}

export function removeOtherPayTypeKey () {
  window.localStorage.removeItem(otherPayTypeKey)
}

export function getPackageUnit () {
  let val = window.localStorage.getItem(packageUnitKey)
  return JSON.parse(val)
}

export function setPackageUnit (packageUnit) {
  window.localStorage.setItem(packageUnitKey, JSON.stringify(packageUnit))
}

export function removePackageUnitKey () {
  window.localStorage.removeItem(packageUnitKey)
}

export function getPickupDeliveryMethod () {
  let val = window.localStorage.getItem(pickupDeliveryMethodKey)
  let pickupDeliveryList = JSON.parse(val)
  pickupDeliveryList.forEach((element, index) => {
    if (element.code === 'P') {
      element.description = 'Pick-Up from Shipper'
    }
    if (element.code === 'D') {
      element.description = 'Delivery by Shipper'
    }
    if (element.code === 'A') {
      pickupDeliveryList.splice(index, 1)
    }
  })
  return pickupDeliveryList
}

export function setPickupDeliveryMethod (pickupDeliveryMethod) {
  window.localStorage.setItem(
    pickupDeliveryMethodKey,
    JSON.stringify(pickupDeliveryMethod)
  )
}

export function removePickupDeliveryMethodKey () {
  window.localStorage.removeItem(pickupDeliveryMethodKey)
}

export function getSentMethod () {
  let val = window.localStorage.getItem(sentMethodKey)
  return JSON.parse(val)
}

export function setSentMethod (sentMethod) {
  window.localStorage.setItem(sentMethodKey, JSON.stringify(sentMethod))
}

export function removeSentMethodKey () {
  window.localStorage.removeItem(sentMethodKey)
}

export function getServiceLevel () {
  let val = window.localStorage.getItem(serviceLevelKey)
  return JSON.parse(val)
}

export function setServiceLevel (serviceLevel) {
  window.localStorage.setItem(serviceLevelKey, JSON.stringify(serviceLevel))
}

export function removeServiceLevelKey () {
  window.localStorage.removeItem(serviceLevelKey)
}

export function getServiceScope () {
  let val = window.localStorage.getItem(serviceScopeKey)
  return JSON.parse(val)
}

export function setServiceScope (serviceScope) {
  window.localStorage.setItem(serviceScopeKey, JSON.stringify(serviceScope))
}

export function removeServiceScopeKey () {
  window.localStorage.removeItem(serviceScopeKey)
}

export function getServiceType () {
  let val = window.localStorage.getItem(serviceTypeKey)
  return JSON.parse(val)
}

export function setServiceType (serviceType) {
  window.localStorage.setItem(serviceTypeKey, JSON.stringify(serviceType))
}

export function removeServiceTypeKey () {
  window.localStorage.removeItem(serviceTypeKey)
}

export function getShowNotifyPartyOnAWB () {
  let val = window.localStorage.getItem(showNotifyPartyOnAWBKey)
  return JSON.parse(val)
}

export function setShowNotifyPartyOnAWB (showNotifyPartyOnAWB) {
  window.localStorage.setItem(
    showNotifyPartyOnAWBKey,
    JSON.stringify(showNotifyPartyOnAWB)
  )
}

export function removeShowNotifyPartyOnAWBKey () {
  window.localStorage.removeItem(showNotifyPartyOnAWBKey)
}

export function getBookingStatus () {
  let val = window.localStorage.getItem(bookingStatusKey)
  return JSON.parse(val)
}

export function setBookingStatus (bookingStatus) {
  bookingStatus.unshift({
    code: 'ALL',
    description: 'ALL',
    sequence: 0
  })
  bookingStatus.some((item) => {
    if (item.code === 'N') {
      item.description = 'Draft'
      return true
    }
  })
  window.localStorage.setItem(bookingStatusKey, JSON.stringify(bookingStatus))
}

export function removeBookingStatusKey () {
  window.localStorage.removeItem(bookingStatusKey)
}

export function getTransferFlag () {
  let val = window.localStorage.getItem(transferFlagKey)
  return JSON.parse(val)
}

export function setTransferFlag (tansferFlag) {
  window.localStorage.setItem(transferFlagKey, JSON.stringify(tansferFlag))
}

export function removeTransferFlagKey () {
  window.localStorage.removeItem(transferFlagKey)
}

export function getValueAddedServiceCode () {
  let val = window.localStorage.getItem(valueAddedServiceCodeKey)
  return JSON.parse(val)
}

export function setValueAddedServiceCode (valueAddedServiceCode) {
  window.localStorage.setItem(
    valueAddedServiceCodeKey,
    JSON.stringify(valueAddedServiceCode)
  )
}

export function removeValueAddedServiceCodeKey () {
  window.localStorage.removeItem(valueAddedServiceCodeKey)
}

export function getVolumeUnit () {
  let val = window.localStorage.getItem(volumeUnitKey)
  return JSON.parse(val)
}

export function setVolumeUnit (volumeUnit) {
  window.localStorage.setItem(volumeUnitKey, JSON.stringify(volumeUnit))
}

export function removeVolumeUnitKey () {
  window.localStorage.removeItem(volumeUnitKey)
}

export function getWeightUnit () {
  let val = window.localStorage.getItem(weightUnitKey)
  return JSON.parse(val)
}

export function setWeightUnit (weightUnit) {
  window.localStorage.setItem(weightUnitKey, JSON.stringify(weightUnit))
}

export function removeWeightUnitKey () {
  window.localStorage.removeItem(weightUnitKey)
}

export function getTransferReorcod () {
  let val = window.localStorage.getItem(transferKey)
  return JSON.parse(val)
}

export function setTransferReorcod (transferReorcod) {
  window.localStorage.setItem(transferKey, JSON.stringify(transferReorcod))
}

export function removeTransferReorcodKey () {
  window.localStorage.removeItem(transferKey)
}

export function clearLocalStorage () {
  window.localStorage.clear()
}
