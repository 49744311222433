// import { Base64 } from 'js-base64'
import Cookies from 'js-cookie'

const TokenKey = 'limosa-token' // "limosa_token"
const NameKey = 'limosa-username' // "limosa_name"
const officeCodeKey = 'limosa-officeCode' // 'limosa_officeCode'
const MENU_KEY = 'menuKey'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getName () {
  return Cookies.get(NameKey)
}

export function setName (name) {
  return Cookies.set(NameKey, name)
}

export function removeName () {
  return Cookies.remove(NameKey)
}

export function getOfficeCode () {
  return Cookies.get(officeCodeKey)
}

export function setOfficeCode (officeCode) {
  return Cookies.set(officeCodeKey, officeCode)
}

export function removeOfficeCode () {
  return Cookies.remove(officeCodeKey)
}

export function getMenukey () {
  let menuKeyArr = []
  let menuKeyValue = Cookies.get(window.btoa(MENU_KEY))
  if (menuKeyValue) {
    menuKeyValue = window.atob(menuKeyValue)
    menuKeyArr = JSON.parse(menuKeyValue)
  }
  return menuKeyArr
}

export function setMenukey (menuKeyArr) {
  menuKeyArr = window.btoa(JSON.stringify(menuKeyArr))
  const menuKey = window.btoa(MENU_KEY)
  return Cookies.set(menuKey, menuKeyArr)
}

export function removeMenukey () {
  return Cookies.remove(window.btoa(MENU_KEY))
}
