<template>
  <div id="app">
    <router-view/>
    <Loading></Loading>
  </div>
</template>

<script>
import Loading from './components/loading.vue'
export default {
  name: 'app',
  components: {
    Loading
  }
}
</script>

<style lang="scss">
@import url('./styles.scss');
@import url('./assets/icons/fonts/icomoon.css');
body{
  margin: 0;
  padding: 0;
}
</style>
